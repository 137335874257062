import React from 'react'

export const IPadBuyingGuide = () => {
    return (
        <div className='container mt-3'>
            <div><a className='link' href='/'>&#10094; Home</a></div>
            <br />
            <div>
                <h2 className='text-muted'>iPad buying guide for - <span className='text-dark'>YourBrief</span></h2>
            </div>
            <br />
            <hr />
            <br />
            <div className='container'>
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col" className='text-muted'>#</th>
                        <th scope="col" className='text-muted'>Combo</th>
                        <th scope="col" className='text-muted'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th scope="row">1</th>
                        <td>
                            Top-end (<a className='link' target='_blank' href='https://www.amazon.in/hz/wishlist/ls/1L9DDLCMPR1HV?ref_=wl_share'>iPad Pro + Apple pencil 2nd gen</a>)
                        </td>
                        <td>
                            <ul className='list-group'>
                                <li className='list-group-item'>Heavy usage</li>
                                <li className='list-group-item'>Larger projects</li>
                                <li className='list-group-item'>Larger file sizes</li>
                            </ul>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">2</th>
                        <td>Mid range (<a className='link' target='_blank' href='https://www.amazon.in/hz/wishlist/ls/32MUZW46O3VYG?ref_=wl_share'>iPad Air + Apple pencil 2nd gen</a>)</td>
                        <td>
                            <ul className='list-group'>
                                <li className='list-group-item'>Heavy usage</li>
                                <li className='list-group-item'>Average projects</li>
                                <li className='list-group-item'>Average file sizes</li>
                            </ul>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">3</th>
                        <td>Budget range (<a className='link' target='_blank' href='https://www.amazon.in/hz/wishlist/ls/2KLO4WXDI46W9?ref_=wl_share'>iPad + Apple pencil 1st gen</a>)</td>
                        <td>
                            <ul className='list-group'>
                                <li className='list-group-item'>Average usage</li>
                                <li className='list-group-item'>Average projects</li>
                                <li className='list-group-item'>Smaller file sizes</li>
                            </ul>
                        </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
            </div>
        </div>
    )
}
