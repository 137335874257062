import React from "react"

const PageNotFound = (props) => {
    return (
        <div>
            <div className="error404">
                <p>Error <span className="text-danger">404</span> page not found</p>
                <a className="text-danger" href="/">Fall back to safe zone?</a>
            </div>
        </div>
    )
}

export default PageNotFound