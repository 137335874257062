import React, { useState } from "react"
import Navbar from "../components/Navbar"
import Firebase from "../firebase/Firebase"
import { useForm } from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toast } from "react-toastify"
import SpinnerService from "../services/SpinnerService"

const Feedback = (props) => {

    const [errors, setErrors] = useState([])

    const schema = yup.object().shape({
        name: yup.string().required(),
        phone: yup.number(),
        email: yup.string().email().required(),
        feedback: yup.string().required(),
    })

    const prepareError = (errorMessage) => {
        let errorCleaned = errorMessage.split(",")[0]
        return `${errorCleaned[0].toUpperCase()}${errorCleaned.slice(1)}`
    }

    const onSubmit = (data, e) => {
        SpinnerService.show()
        setErrors([])
        Firebase
            .firestore()
            .collection('your-brief')
            .doc()
            .set(data)
            .then(()=>{
                SpinnerService.hide()
                toast.success("You have successfully sent the feedback")
                props.history.push("/")
            })
            .catch(error => {
                SpinnerService.hide()
                console.log(error);
            })
    }
    const onError = (data, e) => {
        console.log("Error", data, e)
        var listOErrors = []
        Object.keys(data).forEach(key => {
            listOErrors.push(data[key].message)
        })
        setErrors(listOErrors)
        console.log(listOErrors);
    }

    const {register, handleSubmit, formState} = useForm({
        resolver: yupResolver(schema)
    })

    return (
        <div>
            <Navbar page="feedback" />
            <div className="container mt-5">
                <form className="container border p-3" style={{maxWidth: "450px"}} onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="form-group">
                        <label>Name</label>
                        <input className="form-control" {...register("name")} type="text" />
                        {}
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input className="form-control" {...register("phone")} />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" {...register("email")} />
                        <small className="form-text text-muted">We will not share your details with anyone.</small>
                    </div>
                    <div className="form-group">
                        <label>Feedback</label>
                        <textarea className="form-control" {...register("feedback")} ></textarea>
                        <small className="form-text text-muted">Maybe we will reward you for this.</small>
                    </div>
                    
                    {errors.length > 0 && <small className="text-danger">
                        {prepareError(errors[0])}
                    </small>}
                    
                    <input value="Submit" type="submit" className="btn mt-3 w-100" style={{backgroundColor: "#464646", color: "white"}} />
                </form>
            </div>
        </div>
    )
}

export default Feedback