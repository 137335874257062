import React, {useEffect, useState} from "react"
import TextTransition, {presets} from "react-text-transition"
import AppstoreLink from "../components/AppstoreLink"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

const Home = (props) => {

    const Texts = [
        "Prepare",
        "Manage",
        "Argue"
    ]
    
    const [textIndex, setTextIndex] = useState(0)

    React.useEffect(() => {
        const intervalId = setInterval(() =>
        setTextIndex(textIndex => textIndex + 1),
          2000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
      }, []);

    return (
        <div>
            <Navbar page="home" />
            <div className="w-100 p-2 d-flex justify-content-center align-items-center" style={{height: "300px", fontSize: "3rem", color: "white", backgroundColor: "#464646"}}>
                <TextTransition text={ Texts[textIndex % Texts.length] } springConfig={ presets.wobbly } />
            </div>
            <div className="image-desc">
                <div>
                    <img src="assets/product-images/home.png"></img>
                </div>
                <div className="desc">
                    <p className="large-text">Overview</p>
                    <p className="content">
                    ‘Your brief’ is a legal writing application specially designed for lawyers to create and manage clients, create briefs, dictate and do many more exciting things like never before. This application was ideated by working closely with legal professionals and understanding  their professional requirements. This will help you improve your productivity upto 10 times.
                    </p>
                </div>
            </div>
            <div className="image-desc">
                <div>
                    <img src="assets/product-images/cases.png"></img>
                </div>
                <div className="desc">
                    <p className="large-text">Create and manage cases</p>
                    <p className="content">
                    You can create briefs, manage them. One of the biggest problems of managing the client documents and storing them in a safe environment is now more affordable with your iPad. You can prepare your brief importing as many documents as you want. And your confidential client data is stored on your iPad securely. More exciting things about creating case is in the <a href="#workspace" className="content-link">workspace</a> section make sure you don’t miss it.
                    </p>
                </div>
            </div>
            <div className="image-desc">
                <div>
                    <img src="assets/product-images/clients.png"></img>
                </div>
                <div className="desc">
                    <p className="large-text">Create and manage clients</p>
                    <p className="content">
                    You can create client contact information like adding them with name, email and phone number. You can create initial note and jot down the basic notes on the clients problem, So that you can work on that later. This creates a mini CRM and help you improve and maintain your customer relations.
                    </p>
                </div>
            </div>
            <div className="image-desc">
                <a name="workspace"></a>
                <div>
                    <img src="assets/product-images/workspace.png"></img>
                </div>
                <div className="desc">
                    <p className="large-text">Workspace</p>
                    <p className="content">
                    This part is specially designed for lawyers, keeping all their requirements into consideration. Here in workspace you can flawlessly create your briefs, jot notes, create hyperlinks between notes and document for easy navigation, You can dictate, store audios and texts from the dictation, you can navigate to any document, page just in few clicks. You can also save the draft and work on it later. You get productive options like marking documents with annotations, highlights and many more.
                    </p>
                </div>
            </div>
            <div className="download-store m-3">
                <AppstoreLink />
            </div>
            <br />
            <div>
                <center><span>Confused buying iPad? Here's - </span><a className="link" target="_blank" href="/ipad-buying-guide">iPad buying guide</a></center>
            </div>
            <br />
            <Footer />
        </div>
    )
}

export default Home