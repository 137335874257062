import React from "react"

const Navbar = (props) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: "#464646"}}>
            <a className="navbar-brand" href="#" style={{letterSpacing: 1}}>Your &nbsp;<i className="fas fa-briefcase"></i> &nbsp;Brief</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                <li className={(props.page == "home") ? "nav-item active" : "nav-item"}>
                    <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className={(props.page == "feedback") ? "nav-item active" : "nav-item"}>
                    <a className="nav-link" href="/feedback">Feedback</a>
                </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar