import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Feedback from './pages/Feedback';
import PageNotFound from './pages/PageNotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './components/Spinner';
import { IPadBuyingGuide } from './pages/IPadBuyingGuide';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/ipad-buying-guide" exact component={IPadBuyingGuide} />
          <Route path="" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer position="bottom-right" hideProgressBar />
      <Spinner />
    </div>
  );
}

export default App;
