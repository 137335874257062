import React from "react"

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="footer-logo text-light large-text">
                <p>Your &nbsp; <i className="fa fa-briefcase"></i> &nbsp; Brief</p>
            </div>
            <div className="footer-social-links text-light">
                <a href="mailto:ilus.client@gmail.com" className="text-light"><i className="fas fa-at large-text"></i> &nbsp;Write us</a>
                <a href="https://www.facebook.com/ilus.inc" target="_blank" className="text-light"><i className="fab fa-facebook-f large-text"></i> &nbsp;Facebook</a>
                <a href="https://www.instagram.com/ilus.inc" target="_blank" className="text-light"><i className="fab fa-instagram large-text"></i> &nbsp;Instagram</a>
                <a href="https://api.whatsapp.com/send?phone=7348925693" target="_blank" className="text-light"><i className="fab fa-whatsapp large-text"></i> &nbsp; WhatsApp</a>
            </div>
        </div>
    )
}

export default Footer