class SpinnerService{
    static show(){
        document.getElementById("spinner").setAttribute('aria-hidden', false)
        document.getElementById("body").style.overflowY = "hidden"
    }

    static hide(){
        document.getElementById("spinner").setAttribute('aria-hidden', true)
        document.getElementById("body").style.overflowY = "scroll"
    }
}

export default SpinnerService