import React from "react"
import { ClipLoader } from "react-spinners"


const Spinner = (props) => {
    return (
        <div id="spinner" aria-hidden={true} className="spinner">
            <ClipLoader color="#fff" size={50} />
            <small id="spinner-text" className=" text-light mt-2">Loading</small>
        </div>
    )
}

export default Spinner